import AppRouter from "./routing/AppRouter";


const App = () => {
  
  return (
    <>
      <AppRouter />
    </>
  );
};

export default App;
